<template>
    <div>
        <b-modal id="modal-xl-map" size="xl" title="Схема проезда">
            <b-carousel id="carousel-1"
                        v-model="slide"
                        :interval="6000"
                        controls
                        indicators
                        background="#ababab"
                        img-width="1024"
                        img-height="470"
                        @sliding-start="onSlideStart"
                        @sliding-end="onSlideEnd"
                        class="shadow rounded">

                <!--<b-carousel-slide>
                    <div class="map">
                        <yandex-map :coords="coords"
                                    zoom=10
                                    @click="onClick">
                            <ymap-marker :coords="coords"
                                         marker-id="123123"
                                         :balloon-template="balloonTemplate" />
                        </yandex-map>
                    </div>

                </b-carousel-slide>-->
                <b-carousel-slide img-src="\images\map1.jpg">
                    <a href="https://yandex.ru/maps/?um=constructor%3A390e4c56bf808df310c5c517c8ed8e4ca6e55808eaff32369e628945588ce195&source=constructorLink" target="_blank" style="        color: orange;
        font-size: 20px;
        font-weight: bold;
        font-family: Roboto;
        text-decoration: none
" class="alert-link">на большую карту</a>
                </b-carousel-slide>
                <b-carousel-slide img-src="\images\s5.jpg"></b-carousel-slide>
                <b-carousel-slide img-src="\images\s7.jpg"></b-carousel-slide>
                <b-carousel-slide img-src="\images\s3.jpg"></b-carousel-slide>
                <b-carousel-slide img-src="\images\s1.jpg"></b-carousel-slide>
                <b-carousel-slide img-src="\images\s4.jpg"></b-carousel-slide>
                <b-carousel-slide img-src="\images\s2.jpg"></b-carousel-slide>
                <b-carousel-slide img-src="\images\s6.jpg"></b-carousel-slide>
            </b-carousel>
            <template #modal-footer="{ ok }">
                <b-button class="pb-2 p-2 mr-4" pill variant="warning" style="min-width:150px;" @click="ok()">Закрыть</b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
    export default {
      
    }
</script>