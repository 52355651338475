<template>
        <b-modal id="modal-xl-price" size="xl" :title="date">
                <b-card-group columns style="width:auto; column-count: 4; font-size: 1.5rem;">
                    <b-card v-for="item in items" :key="item" style="height:160px; background-color:#C0C0C0"  
                            text-variant="dark"
                            class="text-center shadow rounded mb-4"
                            v-bind:title="item.Title">
                        <b-card-text>{{item.Price}} р.</b-card-text>
                    </b-card>
                </b-card-group>
            <template #modal-footer="{ ok }">
                <b-button class="pb-2 p-2 mr-4" pill variant="warning" style="min-width:150px" @click="ok()">Закрыть</b-button>
            </template>
        </b-modal>
</template>

<script>
    import axios from 'axios'

    export default {

        data() {
            return {
                date: '',
                items: []
            }
        },
        methods: {
            async fetchData() {
                const { data } = await axios.get('/good/list');
                this.items = data;
                }           
        },
        mounted() {
            this.date = 'Прайс лист от' + " " + new Date().toLocaleDateString();
            this.fetchData();
        }
    }
</script>