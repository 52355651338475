<template>
    <div id="app">
        <!--<img alt="Vue logo" src="./assets/logo.png">-->
        <!--<HelloWorld msg="Welcome to Your Vue.js App"/>-->
        <NavClient></NavClient>
        <!--<MenuClient></MenuClient>-->
        <ModalLicense></ModalLicense>
        <ModalPrice></ModalPrice>
        <FooterClient></FooterClient>
        <MapClient></MapClient>
        <ModalCont></ModalCont>
        <!--<yandex-map :center="[55.681576, 37.488467]"
                    :zoom="10"
                    :dragable="false"
                    :scrollZoom="false"                   
                    @created="mapCreated">
            @destroy="mapDestroy">
            <region-select button-text="Please select ..."
                           :region="[[55.761104221485205, 37.589244608215324],[55.753360214866454, 37.519893411926276], [55.74329069752624, 37.57207847052001]]"
                           @changed="regionChanged">
            </region-select>
        </yandex-map>
        <yandexMap></yandexMap>-->
</div>
</template>

<script>
   /* import  yandexMap from 'vue-yandex-maps'*/
    //import HelloWorld from './components/HelloWorld.vue'
    import NavClient from './components/NavClient.vue'
    //import MenuClient from './components/MenuClient.vue'
    import ModalLicense from './components/ModalLicense.vue'
    import ModalPrice from './components/ModalPrice.vue'
    import FooterClient from './components/FooterClient.vue'
    import MapClient from './components/MapClient.vue'
    import ModalCont from './components/ModalCont.vue'

export default {
        name: 'app',
        data: () => ({
            coords: [54, 39],
            settings: {
                version: '2.1',
                lang: 'ru_RU',
                apiKey: '',
            }
        }),
  components: {
      NavClient,
      ModalLicense,
      ModalPrice,
      FooterClient,
      MapClient,
      ModalCont,
      }
}
</script>

<style>
    body {
        position: relative;
        min-height: 100%;
        background: url(/images/fon04.jpg);
        background-repeat: no-repeat;
    }

    #app {
        font-family: Roboto, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 0px;
    }

    .end {
        bottom: 0;
        width: 100%;
        height: 8vh;
    }
    .modal-header {
        background-color: #878EFA;
        background-size: 100%;
        text-align: center;
        font-family: Roboto;
    }
    .modal-footer {
        background-color: #878EFA;
        background-size: 100%;
        font-family: Roboto;
    }
    .modal-content {
        border: none !important
    }
  
</style>
